/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import {StaticQuery, graphql} from "gatsby";

import './layout.css';
import 'typeface-montserrat';
import 'normalize.css';

export const designSystem = {
    palette: {
        mainPurple: 'hsla(270, 83%, 34%, 1)',
        darkPurple: 'hsla(270, 83%, 23%, 1)',
        lightPurple: 'hsla(270, 55%, 70%, 1)',
        xtraLightPurple: 'hsla(270, 100%, 90%, 1)',
        seeThroughPurple: 'hsla(253, 32%, 54%, 0.4)',
        contrast: 'hsla(150, 82%, 49%, 1)',
        contrastLight: 'hsla(150, 100%, 85%, 1)',
        white: 'hsla(0, 0%, 100%, 1)',
        black: 'hsla(0, 0%, 0%, 1)',
        seeThroughBlack: 'hsla(0, 0%, 0%, 0.1)',
        lightGrey: 'hsla(0, 0%, 79%, 1)',
        mediumGrey: 'hsla(0, 0%, 67%, 1)',
        bodyTextColour: 'hsl(204, 11%, 18%)',
        transparent: 'transparent'
    },
    padding: {
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: '15px',
        paddingBottom: '15px',
        padding: '15px'
    },
    layout: {
        borderRadiusStandard: '10px',
        borderRadiusPill: '20px',
        decorativeBorder: '16px'
}
};

const Layout = ({children}) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <div
                    className="content"
                >
                    {children}
                </div>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
